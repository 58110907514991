import * as React from 'react';
import Link from 'components/Link';
import firebase from 'firebase';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { useApolloClient, ApolloClient } from '@apollo/client';

import useViewer from 'hooks/useViewer';
import useStripe from 'hooks/useStripe';
import Head from 'components/Head';
import { getIsNativePlatform } from 'utils/client/mobile';
import { SIGNUP_PAGE, FORGOT_PASSWORD_PAGE, OPTION_FLOW_PAGE } from 'constants/pages';
import { getPriceIdForRedirect, isValidPriceId } from 'constants/payments';
import { AuthStatus } from 'constants/auth';
import { auth } from 'services/client/firebase';
import { updateViewerToken, hasDashboardAccess } from 'services/client/token';
import api from 'services/client/api';
import Sentry from 'utils/shared/sentry';
import { RequestStatus, ErrorResponse } from 'constants/requests';
import FormWrapper from './FormWrapper';
import { GET_AUTH_USER } from './graphql';

import { Input, Label, FieldWrapper, Button } from 'styles/elements';
import { FormTitle, BottomLink, ErrorWrapper, TextLink } from './styles';
import Google from 'svg/Google';

const TITLE = 'Log In';
const DESCRIPTION = 'Log in to your InsiderFinance account';
const POLL_TIME_MS = 500;
const ONE_MINUTE = 60 * 1000;
const MAX_POLLS = ONE_MINUTE / POLL_TIME_MS;

type Resolve = (value: unknown) => void;
type Reject = (reason?: any) => void;

const pollForUser = (client: ApolloClient<object>, user: firebase.User | null) => {
  let requestMade = 0;
  let success = false;

  if (!user?.uid) {
    return Promise.reject(new Error('Did not have a valid user ID'));
  }

  const makeQuery = async (resolve: Resolve, reject: Reject) => {
    requestMade = requestMade + 1;

    if (requestMade === MAX_POLLS) {
      reject(new Error('User poll timed out'));
    }

    const result = await client.query({
      query: GET_AUTH_USER,
      variables: { id: user.uid },
      fetchPolicy: 'no-cache',
    });

    if (result.errors) {
      return reject(result.errors);
    } else if (result.data && result.data.users_by_pk) {
      success = true;
      return resolve(result.data.users_by_pk);
    } else if (!success) {
      setTimeout(makeQuery, POLL_TIME_MS, resolve, reject);
    }
  };

  return updateViewerToken(user, true).then((resp) => {
    if (!resp.token) {
      throw new Error('Could not find user token');
    }

    return new Promise(makeQuery);
  });
};

interface CheckoutSessionArgs {
  product: string;
  term: string;
  user?: firebase.User | null;
  stripe?: stripe.Stripe;
}

const maybeCreateCheckoutSession = ({ product, term, user, stripe }: CheckoutSessionArgs) => {
  const priceId = isValidPriceId(product as string)
    ? product
    : getPriceIdForRedirect(product as string, term as string);

  let checkoutSessionPromise: Promise<any> = Promise.resolve(false);

  if (priceId && stripe) {
    checkoutSessionPromise = !user
      ? Promise.resolve(false)
      : user.getIdToken().then(async (idToken) =>
          api
            .post('payments/stripe/checkout', {
              payload: {
                token: idToken,
                stripePriceId: priceId,
              },
            })
            .then(async (session) => {
              if (session && session.statusCode && session.statusCode > 201 && session.message) {
                throw new Error(session.message);
                return;
              }

              if (!session || !session.id) {
                throw new Error('Could not create subscription. Refresh the page and try again.');
                return;
              }

              // NOTE: This was not recognizing the custom domain
              // await stripe.redirectToCheckout({
              //   sessionId: session.id,
              // });

              window.location.href = session.url;

              return Promise.resolve(true);
            }),
        );
  }

  return checkoutSessionPromise;
};

const Login = () => {
  const router = useRouter();
  const viewer = useViewer();
  const stripe = useStripe({});
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isCreatingSession, setIsCreatingSession] = React.useState(false);
  const [isBillingIssueModalOpen, setIsBillingIssueModalOpen] = React.useState(false);
  const [error, setError] = React.useState<ErrorResponse | null>(null);
  const [requestStatus, setRequestStatus] = React.useState(RequestStatus.Idle);
  const isDisabled =
    requestStatus === RequestStatus.InProgress ||
    requestStatus === RequestStatus.Success ||
    viewer.status === AuthStatus.User;
  const isHidden =
    !getIsNativePlatform() &&
    (viewer.status === AuthStatus.Loading || viewer.status === AuthStatus.User) &&
    !error &&
    requestStatus !== RequestStatus.InProgress &&
    requestStatus !== RequestStatus.Success;
  const client = useApolloClient();

  const { product, term } = router.query;
  let pathname = '';
  if (product) {
    if (term) {
      pathname = `/${product}/${term}`;
    } else {
      pathname = `/${product}`;
    }
  }

  React.useEffect(() => {
    const isIdle =
      requestStatus !== RequestStatus.InProgress &&
      requestStatus !== RequestStatus.Success &&
      !isCreatingSession;
    const hasViewer = viewer.status === AuthStatus.User && viewer.viewer;

    if (hasViewer && product && stripe && isIdle) {
      setIsCreatingSession(true);
      maybeCreateCheckoutSession({
        product: product as string,
        term: term as string,
        stripe,
        user: viewer.viewer,
      });
    } else if (hasViewer && isIdle && stripe && !product) {
      const next = router.query.next as string;
      const redirectUrl = next || OPTION_FLOW_PAGE;
      if (getIsNativePlatform()) {
        router.push(redirectUrl);
      } else {
        window.location.href = redirectUrl;
      }
    }
  }, [viewer, product, term, stripe, requestStatus, isCreatingSession]);

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();

      if (requestStatus !== RequestStatus.InProgress && requestStatus !== RequestStatus.Success) {
        setRequestStatus(RequestStatus.InProgress);
        setError(null);

        auth
          .signInWithEmailAndPassword(email, password)
          .then(function (user) {
            setRequestStatus(RequestStatus.Success);
            setIsCreatingSession(true);

            if (getIsNativePlatform()) {
              return false;
            } else {
              return maybeCreateCheckoutSession({
                product: product as string,
                term: term as string,
                stripe,
                user: user.user,
              });
            }
          })
          .then(async (isRedirecting) => {
            if (!isRedirecting) {
              const next = router.query.next as string;
              const redirectUrl = next || OPTION_FLOW_PAGE;
              if (getIsNativePlatform()) {
                router.push(redirectUrl);
              } else {
                window.location.href = redirectUrl;
              }
            } else if (getIsNativePlatform()) {
              const currentUser = auth.currentUser;
              const { user, claims } = await updateViewerToken(currentUser, true);
              const isAllowedToRedirect = hasDashboardAccess({
                status: AuthStatus.User,
                viewer: user,
                config: null,
                claims: claims,
              });

              if (isAllowedToRedirect) {
                window.location.reload();
              } else {
                setIsBillingIssueModalOpen(true);
              }

              setRequestStatus(RequestStatus.Idle);
            }
          })
          // .then(function (settings) {
          //   if (settings && settings.subscriptions && settings.subscriptions.length > 0) {
          //     window.location.replace(SETTINGS_PAGE);
          //   } else {
          //     window.location.replace(PRICING_PAGE);
          //   }
          // })
          .catch(function (errorResponse) {
            Sentry.captureException(error);
            setRequestStatus(RequestStatus.Error);
            setError(errorResponse);
          });
      }
    },
    [email, password, requestStatus, stripe, product, term],
  );

  const handleGoogleSignIn = React.useCallback(
    (e: any) => {
      e.preventDefault();

      if (requestStatus !== RequestStatus.InProgress && requestStatus !== RequestStatus.Success) {
        setRequestStatus(RequestStatus.InProgress);
        setError(null);

        const provider = new firebase.auth.GoogleAuthProvider();

        auth
          .signInWithPopup(provider)
          .then(async function (result) {
            setRequestStatus(RequestStatus.Success);
            setIsCreatingSession(true);

            // Poll for user in case this is actually a new signup
            await pollForUser(client, result.user);

            if (getIsNativePlatform()) {
              return false;
            } else {
              return maybeCreateCheckoutSession({
                product: product as string,
                term: term as string,
                stripe,
                user: result.user,
              });
            }
          })
          .then(async (isRedirecting) => {
            if (!isRedirecting) {
              const next = router.query.next as string;
              const redirectUrl = next || OPTION_FLOW_PAGE;
              if (getIsNativePlatform()) {
                router.push(redirectUrl);
              } else {
                window.location.href = redirectUrl;
              }
            } else if (getIsNativePlatform()) {
              const currentUser = auth.currentUser;
              const { user, claims } = await updateViewerToken(currentUser, true);
              const isAllowedToRedirect = hasDashboardAccess({
                status: AuthStatus.User,
                viewer: user,
                config: null,
                claims: claims,
              });

              if (isAllowedToRedirect) {
                window.location.reload();
              } else {
                setIsBillingIssueModalOpen(true);
              }

              setRequestStatus(RequestStatus.Idle);
            }
          })
          .catch(function (errorResponse) {
            Sentry.captureException(errorResponse);
            setRequestStatus(RequestStatus.Error);
            setError({
              statusCode: errorResponse?.code,
              message: errorResponse?.message || 'An error occurred during Google sign in',
            });
          });
      }
    },
    [requestStatus, stripe, product, term, client],
  );

  return (
    <>
      <Head title={TITLE} description={DESCRIPTION} useCurrentUrl />

      <div className="styled-font">
        <FormWrapper isHidden={isHidden}>
          <FormTitle>Log In</FormTitle>
          <div className="mb-4">
            <form onSubmit={handleSubmit}>
              <FieldWrapper>
                <Label>Email</Label>
                <Input
                  disabled={isDisabled}
                  placeholder="Enter your email address"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => {
                    if (!isDisabled) {
                      setEmail(e.target.value);
                    }
                  }}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Label>Password</Label>
                <Input
                  disabled={isDisabled}
                  placeholder="Enter you password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => {
                    if (!isDisabled) {
                      setPassword(e.target.value);
                    }
                  }}
                />
              </FieldWrapper>
              <div className="my-6 flex items-center justify-center font-semibold">
                <Link href={FORGOT_PASSWORD_PAGE} className="text-xs text-gray-400">
                  Forgot Password
                </Link>
              </div>
              <Button disabled={isDisabled} type="submit" className="w-full">
                {isDisabled ? 'Logging in...' : 'Log In'}
              </Button>
            </form>

            <div className="relative my-6">
              <div className="relative flex justify-center text-sm">
                <span className="bg- px-2 text-gray-500">Or continue with</span>
              </div>
            </div>

            <button
              onClick={handleGoogleSignIn}
              disabled={isDisabled}
              className="flex w-full items-center justify-center space-x-2 rounded border border-gray-600 bg-palette-black-3 px-4 py-3.5 text-sm font-semibold text-white transition-colors hover:bg-palette-black-4 disabled:opacity-50"
            >
              <Google className="h-5 w-5" />
              <span>{isDisabled ? 'Logging in...' : 'Continue with Google'}</span>
            </button>

            {error && (
              <ErrorWrapper>
                <div>{error.message || 'An error occurred'}</div>
              </ErrorWrapper>
            )}
          </div>

          {!getIsNativePlatform() ? (
            <BottomLink>
              Don't have an account yet?{' '}
              <Link
                href={`${SIGNUP_PAGE}${pathname}${
                  router.query.next ? `?next=${router.query.next}` : ''
                }`}
              >
                <TextLink>Sign up here</TextLink>
              </Link>
            </BottomLink>
          ) : null}
        </FormWrapper>
      </div>

      <Transition.Root appear show={isBillingIssueModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={async () => {
            await auth.signOut();
            window.location.reload();
            setIsBillingIssueModalOpen(false);
          }}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-palette-black-3 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-palette-black-9"
                      >
                        Billing Issue
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-palette-black-7">
                          Your account has a billing issue. If you believe this is a mistake,
                          contact team@insiderfinance.io
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-button-color px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-800 focus:ring-offset-2 sm:text-sm"
                      onClick={async () => {
                        await auth.signOut();
                        window.location.reload();
                        setIsBillingIssueModalOpen(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Login;
